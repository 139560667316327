import React from 'react';
import Container from '../components/layout/container';
import Section from '../components/sections/section';
import GraphQLErrorList from '../components/graphql-error-list';
import ProjectsGrid from '../components/projects/projects-grid';
import SEO from '../components/layout/seo';
import Layout from '../containers/layout';
import typographyStyles from '../styles/typography.module.css';
import { graphql } from 'gatsby';
import IndustryGrid from '../components/sections/industry-grid';
import { mapEdgesToNodes, sortByTitle } from '../lib/helpers';

export const query = graphql`
  query AllProjectsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    headerBackgroundImage: file(relativePath: { eq: "collaborative-hero.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 815, maxWidth: 1169, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    schools: allSanitySchool {
      nodes {
        ...SchoolPreview
      }
    }
    hiringCompanies: allSanityHiringCompany {
      nodes {
        ...HiringCompanies
      }
    }
    projects: allSanityProject {
      edges {
        node {
          ...ProjectPreview
        }
      }
    }
  }
`;

const ProjectTemplate = (props) => {
  // console.log('ProjectTemplate props: ', props);
  const { data, errors } = props;
  const site = (data || {}).site;
  return (
    <Layout
      siteSubtitle="Collaborative Projects"
      siteTitle="Academy of Art University"
      heroImageCaption="Sodium Na &amp; Izzy Britos"
      headerBackgroundImage={data.headerBackgroundImage ? data.headerBackgroundImage : data.backgroundImageFallback}
    >
      <SEO
        title="Collaborative Projects"
        description="Our competitive cross-sectional classes with industry giants like VW Group, General Motors, Stellantis, and NASA mimic real-world professional scenarios and feedback that prepare our students for rewarding careers in the industry..."
        keywords={site.keywords}
      />
      {errors && <SEO title="GraphQL Error" path={props.location.pathname} />}
      {data.projects && (
        <Section color="black" flush>
          <ProjectsGrid showFilters showPagination projects={sortByTitle(mapEdgesToNodes(data.projects))} />
        </Section>
      )}
      <Section color="dark" alignment="center">
        <Container narrower>
          <h1 className={typographyStyles.responsiveTitle1}>About the Projects</h1>
          <p className={typographyStyles.paragraph}>
            Our competitive cross-sectional classes with industry giants like VW Group, General Motors, Stellantis, and
            NASA mimic real-world professional scenarios and feedback that prepare our students for rewarding careers in
            the industry.
          </p>
          <p className={typographyStyles.paragraph}>
            In each case, students learn to empathize with others, collaborate across disciplines, solve problems
            creatively, adapt to changing circumstances, and persist in the face of obstacles. These skills help prepare
            students to thrive in their future professions.
          </p>
        </Container>
      </Section>
      <Section>
        <IndustryGrid hiringCompanies={data.hiringCompanies.nodes} limit="8" />
      </Section>
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
    </Layout>
  );
};

export default ProjectTemplate;
